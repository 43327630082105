import React, { useState, useEffect } from 'react';
import './modal.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import shopIcon from '../assets/shopIcon.svg';
import VivALogo from '../assets/VivALogo.svg';
import axios from 'axios';
const ModalAuth = ({authState, children}) => {

// test to clear


  //useEffect(() => {
  //  const checkStatus = () => {
      //const userKey = sessionStorage.getItem('authStatus');
     // if (userKey !== 'F') {
    //    sessionStorage.clear()

    //    console.log('User keys cleared');
    //  } else {
        
    //    setTimeout(checkStatus, 1000); // Retry after 1 second
    //  }
   // };
  
    //checkStatus();
  //}, []);

  //const [isActive, setIsActive]=useState(true);

  useEffect(() => {
    const checkAuthStatus = () => {
      console.log('checkAuthStatus running from modal')
      const userKey = sessionStorage.getItem('authStatus');
      if (userKey === '') {
      console.log('No user key found, retrying van modal...');
      setTimeout(checkAuthStatus, 1000); // Retry after 1 second
      // XXX bygevoeg na nuutste build ^^
      } else if (userKey !== 'E') {
        console.log('check auth in modal.js1')
        //setIsActive(false);
        window.location.reload()
        console.log('check auth in modal.js2')
        //reload hier

      } else {
        console.log('user is active1');
       // setIsActive(true);
        console.log('user is active2');
      }
    };
  
    checkAuthStatus();
  }, []);




  console.log(authState, 'hier is dit in modal')
  const [data, setData] = useState(new FormData());

  const handleButtonClickKoop = () => {

    //api
    //443
    data.append('windowType', 'winkel leesbaarheidsindeks');
 axios.post('https://leesbaarheidsindeks.viva-afrikaans.org:443/check', data).then((res) => {
console.log('keer terug')
}).then((res) => {
    //api
    console.log('clicked');
    sessionStorage.clear()
    window.parent.location.href = 'https://viva-afrikaans.org/winkel/viva-leesbaarheidsindeks'; 
    // Replace with the desired URL
  })
  .catch((err) => {
     console.error(err);
  }
  );
  };

  const handleButtonClickExit = () => {
    console.log('clicked');
    sessionStorage.clear()
    localStorage.clear()
    window.parent.location.href = 'https://viva-afrikaans.org'; // Replace with the desired URL
  };

  return (
   
    <div onClick={handleButtonClickKoop} className="modal-overlay">
      

      <div className="authModal-container">
      
      <div className='vivaLogo'><img src={VivALogo} alt="VivA logo" width='150px'/></div>
     
  <div  className='close-buttonAuth' onClick={handleButtonClickExit}>
  <HighlightOffIcon/>
 </div>
 {children}
 <div className='shopIcon'>
 <img src={shopIcon} alt="Winkel logo" width='350px'/>
 </div>
 <hr></hr>
 <p>Jou twee gratis soektogte is opgebruik. Klik op VivA-Winkel, en koop die volledige weergawe van die VivA-Leesbaarheidsindeks om onbeperkte toegang te kry.</p>
       
 <div className='submitBtn' onClick={handleButtonClickKoop}>VivA-Winkel</div>
       
        
        <div></div>
      </div>
    </div>
  );
};

export default ModalAuth;