import React, { useState, useEffect } from 'react';
import '../../styles/StyleSheet.css'




const MisModal =()=> {

 return(
<div>
    

<h2>Misindeks</h2>
<p>Die Misindeks dui in die eerste plek vir enige stuk prosa (en ook inligtingtekste) op die aantal jaar skoolopleiding wat vereis word sodat ’n persoon die teks kan verstaan. Dieselfde telling dui egter ook op hoe moeilik of maklik ’n teks is. Byvoorbeeld, ’n telling van 7 beteken dat die persoon 7 jaar skoolopleiding benodig om die teks te verstaan of dat die persoon graad 7 (standerd 5) moes slaag, of dit dui daarop dat die leesbaarheid van die teks standaard is.</p>
<br></br>
<h3>Leesbaarheidsgraadvlakke:</h3>
<li>5 of 6 - Taamlik maklik</li>
<li>7 of 8 - Standaard</li>
<li>9 tot 11 - Taamlik moeilik</li>
<li>12 tot 15 - Moeilik</li>
<li>16 en hoër - Baie moeilik</li>
<br></br>
</div>

 )
}

export default MisModal