import React, { useState, useEffect } from 'react';
import '../../styles/StyleSheet.css'




const McDermidModal =()=> {

 return(
<div>
    

<h2>McDermid Heyns</h2>
<p>Hierdie Afrikaanse formule is op die Engelse Flesch-formule gebaseer, en dit is aanvanklik in Skryfgoed, sagteware om geskrewe tekste te proeflees, ingesluit (Richards et al., 2017:5-6) . 

Hierdie formule is soos volg: 

RE = 138.8989 – (1.0052 x ASL) – (35.4562 x AWL)

RE = voorspelde leesbaarheidstelling; ASL = aantal woorde per sinne (gemiddelde sinslengte); AWL = getal lettergrepe per woord
</p>
<br></br>
<h3>Leesbaarheidsgraadvlakke:</h3>
<li>0-30 Baie moeilik (Tipies wetenskaplike skryfwerk bv. verhandelinge)</li>
<li>30-50 Moeilik</li>
<li>50-60 Redelik moeilik</li>
<li>60-70 Standaard</li>
<li>70-80 Redelik maklik</li>
<li>80-90 Maklik</li>
<li>90-100 Baie maklik</li>
<br></br>
</div>

 )
}

export default McDermidModal