
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/StyleSheet.css';
import '../components/modal.css';
import Modal from './Modal';
import MisModal from './ModalContent/MisModal';
import McDermidModal from './ModalContent/McDermidModal';
import FleschKincaidModal from './ModalContent/FleschKincaidModal';
import FleschReadingEaseModal from './ModalContent/FleschReadingEaseModal';
import MoeiliksteWoordModal from './ModalContent/MoeiliksteWoordModal';
import InfoIcon from '@mui/icons-material/Info';
import ModalAuth from './ModalAuth';

// loader
import { dotWave } from 'ldrs'
// Default values shown
//https://www.npmjs.com/package/ldrs
dotWave.register()


const MainApp = ({authState}) => {

  // check keys vir upload nuwe

  // XXX
  const [isActive, setIsActive]=useState(true);

  useEffect(() => {
    const checkAuthStatus = () => {
      //console.log('checkAuthStatus running')
      const userKey = sessionStorage.getItem('authStatus');
      if (userKey === '') {
     // console.log('No user key found, retrying...');
      setTimeout(checkAuthStatus, 1000); // Retry after 1 second
      // XXX bygevoeg na nuutste build ^^
      } else if (userKey === 'E') {
       // console.log('check auth in mainApp.js1')
        setIsActive(false);
       // console.log('check auth in mainApp.js2')
        //reload hier

      } else {
       // console.log('user is active1');
        setIsActive(true);
      //  console.log('user is active2');
      }
    };
  
    checkAuthStatus();
  }, []);

//check status

//const checkKeyUpload = sessionStorage.getItem('authStatus');
 // if (checkKeyUpload === 'E') {
  //  setIsActive(false);
   // window.location.reload()
 
//console.log(authState, 'hier is value vanuit main app')
  //skryf error handle


//skryf loader

const [isCookie, setIsCookie]= useState('')
  //cookie Auth
  useEffect(() => {
    // Function to set a cookie
    const setCookie = (name, value, days) => {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);
      const cookieValue = encodeURIComponent(value) + (days ? `; expires=${expirationDate.toUTCString()}` : '');
      document.cookie = `${name}=${cookieValue}; path=/`;
    };

    const hasCookie = document.cookie.split(';').some((item) => item.trim().startsWith('VivAToets='));
    const AuthCookie = getCookie('VivAToets');
    setIsCookie(AuthCookie)
    // If the cookie doesn't exist, set it
    if (!hasCookie) {
      setCookie('VivAToets', '12345678', 30); // Change the value and expiration as needed
    }
  }, []); // Empty dependency array ensures the effect runs only once on component mount
//

//get cookie
const getCookie=(cookieName)=> {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [name, value, days] = cookie.trim().split('=');
    if (name === cookieName) {
      return decodeURIComponent(name, value, days);
    }
  }
  return null; // Return null if the cookie is not found
}
//


 //Dropdowns
 const [selectedOption, setSelectedOption] = useState("option1");

 const  handleDropdownChange = (event) => {
     setSelectedOption(event.target.value);
 };

//forms
// title info
const  [inputTitle, setInputTitle] =  useState('');
 const  handleTitle = (event) => {
     setInputTitle(event.target.value);
 };

// Author info
const  [inputAuthor, setInputAuthor] =  useState('');
 const  handleAuthor = (event) => {
     setInputAuthor(event.target.value);
 };

// leesStuk data
const  [inputLeesStuk, setInputLeesStuk] =  useState('');
//count words in inputLeesStuk
const [leesStukCount, setLeesStukCount] = useState('')

 const  handleLeesStuk = (event) => {
     setInputLeesStuk(event.target.value);
 setLeesStukCount(countWords(inputLeesStuk))

 };

const countWords = (str) => {
 const words = str.split(/\s+/);
 return words.length;
};

// Render button vir koop

const handleButtonClickKoop = () => {
  //console.log('clicked');
  sessionStorage.clear()
  window.parent.location.href = 'https://viva-afrikaans.org/winkel/viva-leesbaarheidsindeks'; // Replace with the desired URL
};

useEffect(() => {
 setLeesStukCount(countWords(inputLeesStuk))
}, [inputLeesStuk]);

// State vir greyedButton en word count condition
// handleMinWoorde
const  handleMinWoorde = () => {
alert('Jy moet ten minste 100 woorde laai om te kan bereken.')
}

//handleVeldeKort
const [isVeldeKort, setIsVeldeKort] =useState(false)

const handleVeldeKort = () => {
  let inhoud = ('')
  if (inputAuthor === '' && inputTitle === ''){inhoud = '\'n titel en outeursnaam'}
  else if (inputAuthor === ''){inhoud = '\'n Outeurnaam'}
  else if (inputTitle === ''){inhoud = '\'n titel'}
  alert('Voorsien asseblief ' + inhoud + ' om te kan bereken.')
  setIsVeldeKort(true)
  }

//handleLaaiWoorde 

// return data
const [returnData, setReturnData] = useState('')
const [taalCheck, setTaalCheck] = useState('')
const [scoreMis,setScoreMis] = useState('')
const [misFeedBack, setMisFeedBack] = useState('')
const [misGraadFeedBack, setMisGraadFeedBack] = useState('')
const [isMisNaSkool, setIsMisNaSkool] = useState(false)


const [scoreMcdermid,setScoreMcdermid] = useState('')
const [mcDermidFeedBack, setMcDermidFeedBack] = useState('')

const [scoreFleschKin,setScoreFleschKin] = useState('')
const [scoreWoord,setScoreWoord] = useState('')
const [scoreMoeilkheid,setScoreMoeilkheid] = useState('')
const [scoreAantal, setScoreAantal] = useState('')
const [scoreSinne, setScoreSinne] = useState('')
const [scoreFlesch ,setScoreFlesch] = useState('')
const [scoreFleschFeedBack, setScoreFleschFeedBack] = useState('')

const [toetsWoorde, setToetsWoorde] = useState('')

// Api send

const [data, setData] = useState(new FormData());

const [progress, setProgress] = useState({ started: false });

// loader ui en state changer

const [isLoadingState, setIsLoadingState] = useState(false)
// Function to get the local cookie
const getLocalCookie = () => {
  // Split the cookie string into an array of key-value pairs
  const cookieArray = document.cookie.split('; ');

  // Loop through the array to find the cookie you're looking for
  for (const cookie of cookieArray) {
    const [key, value] = cookie.split('=');

    // Check if the key matches the name of your local cookie
    if (key === 'VivAToets') {
      return value; // Return the value of the local cookie
    }
  }

  // If the cookie is not found, return null or handle accordingly
  return null;
  };

  function encryptString(inputString, secretKey) {
    let result = '';
    for (let i = 0; i < inputString.length; i++) {
      const charCode = inputString.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length);
      result += String.fromCharCode(charCode);
    }
    return result;
  }
  
    const secretKey = "yourSecretKey";
  
// loader state

//check function test
useEffect(() => {
  //console.log(isLoadingState, 'Here is the loading state'); // This will log whenever loadingState changes
}, [isLoadingState]);


const handleUpload =  () => {
//loader:

setIsLoadingState(true);
const userKey = sessionStorage.getItem('key');

if (userKey === 'E') {
 // console.log('check read function 1')
  setIsActive(false);
 // console.log('check read function 2')
  //reload hier
} // else {console.log('active')}

//console.log(isLoadingState, 'hier is loading state')
//console.log(isCookie, 'hier is die cookie')
 //setProgress((prevState) => ({
 //   ...prevState,
//    started: true
// }));

//console.log('key', userKey)
    data.append('userKey', userKey);
    data.append('textAreaContent',inputLeesStuk);
    data.append('author',inputAuthor);
    data.append('title',inputTitle);
    const localCookie = getLocalCookie();
    const encryptedCookie = encryptString(localCookie, secretKey);
//443
    axios.post('https://leesbaarheidsindeks.viva-afrikaans.org:443/read', data, {
      //withCredentials: true, // This is important to include cookies
     headers: {
         'Authorization': `Bearer ${encryptedCookie}`, // Include the local cookie in the Authorization header
         },
 }, {
   withCredentials: true, // This is important to include cookies
  }).then((res) => {
    if (res.data === 'E') {
     // console.log(res.data, 'hier is data')
      sessionStorage.setItem('authStatus', res.data);
     
       window.location.reload();
    
      
    } else {
   // console.log(isCookie)
    // console.log(res.data);
     setReturnData(res.data)
     setScoreFlesch(res.data[0]) // flesch
     setScoreMis(res.data[1])
     setScoreMcdermid(res.data[2])
     setScoreFleschKin(res.data[3])
     setScoreWoord(res.data[5])
     setScoreMoeilkheid(res.data[4])
     setScoreAantal(res.data[6])
     setScoreSinne(res.data[7])
     setTaalCheck(res.data[8])
     setToetsWoorde(res.data[9])

    // console.log('before', isLoadingState);
     setIsLoadingState(false)
    // console.log('after', isLoadingState);
    // console.log(returnData, 'hier is return')

 }}).catch((err) => {
    // console.error(err);
     // alertxx message fout, reload op "ok"
     alert('Iets het fout gegaan. Probeer later weer.')
     resetUpload();
 } 
 );
//  1 flesch, 2 mis, 3 mcdermid, 4 FleschKin
// 5 woord, 6 moeilkheid, 7 aantal, 8 sinne
};

// Taal check
// set van interpretasie

{/* Misindeks
5 of 6 − taamlik maklik
7 of 8 – standaard
9 tot 11 – taamlik moeilik
12 tot 15 – moeilik
16 en hoër – baie moeilik
*/}
//misFeedBack;
useEffect(() => {
 // console.log(taalCheck)
 //console.log(scoreMis, 'score mis')

if (scoreMis >= 12.999999) {
  setIsMisNaSkool(true)
  let misJaar = ''
  misJaar = Math.floor(scoreMis) - 12
  setMisGraadFeedBack(misJaar + " jaar se naskoolse opleiding" )
}

if (scoreMis >= 1 && scoreMis <= 6.999999) {
  setMisFeedBack("Taamlik maklik")
} else if (scoreMis >= 7 && scoreMis <= 8.999999) {
 setMisFeedBack("Standaard")
} else if (scoreMis >= 9 && scoreMis <= 11.999999) {
 setMisFeedBack("Taamlik moeilik")
} else if (scoreMis >= 12 && scoreMis <= 15.999999) {
 setMisFeedBack("Moelik")
} else if (scoreMis >=16) {
  setMisFeedBack("Baie moeilik")
}
}, [scoreMis]);

{/*
90-100 Baie maklik
80-90 maklik
70-80 redelik maklik
60-70 standaard
50-60 Redelik moeilik
30-40 Moeilik
0-30 Baie moeilik
*/}
//mcDermidFeedBack

useEffect(() => {
 // console.log(scoreMcdermid, 'score mis')
  //SetMcDermidFeedBack
  if (scoreMcdermid >= 1 && scoreMcdermid <= 29.999999) {
    setMcDermidFeedBack("Baie moeilik")
  } else if (scoreMcdermid >= 30 && scoreMcdermid <= 39.999999) {
    setMcDermidFeedBack("Moeilik")
  } else if (scoreMcdermid >= 40 && scoreMcdermid <= 59.999999) {
    setMcDermidFeedBack("Redelik moeilik")
  } else if (scoreMcdermid >= 60 && scoreMcdermid <= 69.999999) {
    setMcDermidFeedBack("Standaard")
  } else if (scoreMcdermid >= 70 && scoreMcdermid <= 79.999999) {
    setMcDermidFeedBack("Redelik maklik")
  } else if (scoreMcdermid >= 80 && scoreMcdermid <= 89.999999) {
    setMcDermidFeedBack("Maklik")
  } else if (scoreMcdermid >= 90 && scoreMcdermid <= 100) {
    setMcDermidFeedBack("Baie maklik")
  }
},[scoreMcdermid]);

// scoreFlesch feedback

useEffect(()=>{
if (scoreFlesch >= 0 && scoreFlesch <= 29.999999) {
  setScoreFleschFeedBack('Baie moeilik')
} else if (scoreFlesch >= 30 && scoreFlesch <= 39.999999) {
  setScoreFleschFeedBack('Moeilik')
} else if (scoreFlesch >= 40 && scoreFlesch <= 59.999999) {
  setScoreFleschFeedBack('Redelik moeilik')
} else if (scoreFlesch >= 60 && scoreFlesch <= 69.999999) {
  setScoreFleschFeedBack('Standaard')
} else if (scoreFlesch >= 70 && scoreFlesch <= 79.999999) {
  setScoreFleschFeedBack('Redelik maklik')
} else if (scoreFlesch >= 80 && scoreFlesch <= 89.999999) {
  setScoreFleschFeedBack('Maklik')
} else if (scoreFlesch >= 90 && scoreFlesch <= 100) {
  setScoreFleschFeedBack('Baie maklik')
}
},[scoreFlesch])
//setScoreMisFeedBack
//reset vir nuwe upload

const resetUpload =()=>{
  // XXX
  const checkKey = sessionStorage.getItem('authStatus');
  if (checkKey === 'E') {
    setIsActive(false);
   // console.log('value updated', checkKey);
    window.location.reload();
  } else {
//console.log('normal upload');
  setInputLeesStuk('');
     setInputTitle('');
     setInputAuthor('');
     setData(new FormData());
     setReturnData('')
     setScoreMis('')
     setScoreMcdermid('')
     setScoreFleschKin('')
     setScoreWoord('')
     setScoreMoeilkheid('')
     setScoreAantal('')
     setScoreSinne('')
     setScoreFlesch('')
     setTaalCheck('')
    // console.log('');
     setIsLoadingState(false)
     setIsVeldeKort(false)
     setMisGraadFeedBack('')
     setIsMisNaSkool(false)
     setScoreFleschFeedBack('')
     setToetsWoorde('')
  }
}


//Modal
//set modal value true of false "open or closed"
const [isModalOpen, setIsModalOpen] = useState(false);
//set modal content value if mcDermid display mcDermid

const [isMisModalOpen, setIsMisModalOpen] = useState(false);
const [isMcDModalOpen, setIsMcDModalOpen] = useState(false);
const [isFleschKinModalOpen, setIsFleschKinModalOpen] = useState(false);
const [isFleschModalOpen, setIsFleschModalOpen] = useState(false);
const [isgraadModalOpen, setIsGraadModalOpen] = useState(false);
const [isMoeiliksteWoordModalOpen, setIsMoeiliksteWoordModalOpen] = useState(false);

const openMisModal = () => {setIsModalOpen(true); setIsMisModalOpen(true);}
const openMcDModal = () => {setIsModalOpen(true); setIsMcDModalOpen(true);}
const openFleschKinModal = () => {setIsModalOpen(true); setIsFleschKinModalOpen(true);}
const openFleschModal = () => {setIsModalOpen(true); setIsFleschModalOpen(true);}
const openGraadModal = () => {setIsModalOpen(true); setIsGraadModalOpen(true);}
const openMoeiliksteWoordModal = () => {setIsModalOpen(true); setIsMoeiliksteWoordModalOpen(true);}
const closeModal = () => {setIsModalOpen(false); 
                         setIsMisModalOpen(false);
                         setIsMcDModalOpen(false);
                         setIsFleschKinModalOpen(false);
                         setIsFleschModalOpen(false);
                         setIsGraadModalOpen(false);
                         setIsMoeiliksteWoordModalOpen(false);
                       }
                        

return (
  
  <div><></>
    {!isActive ? (<><ModalAuth authState ={authState}/></>):(<>
    
  <div className='formSection'>
 
  {isLoadingState ? (
    <div className='loader-overlay'>
<l-dot-wave
  size="47"
  speed="1" 
  color="#27a9e1" 
></l-dot-wave>
</div>
): (<></>)}

 <Modal isOpen={isModalOpen} onClose={closeModal}>
  
        {isMisModalOpen ? (<MisModal/>):(<></>)}
        {isMcDModalOpen ? (<McDermidModal/>):(<></>)}
        {isFleschKinModalOpen ? (<FleschKincaidModal/>):(<></>)}
        {isFleschModalOpen ? (<FleschReadingEaseModal/>):(<></>)}
        {isgraadModalOpen ? (<h1>Graad</h1>):(<></>)}
        {isMoeiliksteWoordModalOpen ? (<MoeiliksteWoordModal/>):(<></>)}
        
       
        
      </Modal>
     
     {/* 
		<label>
			Select an option:
				<select  value={selectedOption} onChange={handleDropdownChange}>
				<option  value="option1">Option 1</option>
				<option  value="option2">Option 2</option>
				<option  value="option3">Option 3</option>
			</select>
		</label>
	*/}

  <h3>Titel van teks:</h3>
<form>
<label>

	<input className={isVeldeKort && inputTitle === '' ? 'inputFormEmpty' : 'inputForm'} type="text"  value={inputTitle} maxLength={250} onChange={handleTitle} />
	</label>
    </form>

    <h3>Outeur:</h3>
    <form>
    <label>
	<input className={isVeldeKort && inputAuthor === '' ? 'inputFormEmpty' : 'inputForm'} type="text"  value={inputAuthor} maxLength={250} onChange={handleAuthor} />
	</label>
    </form>
    <h3>Leesstuk:</h3>
    <form>
    <label>
      <></>
    {returnData == '' ? (<>
{ inputAuthor === '' || inputTitle === '' ? (<textarea placeholder='Laai 100 of meer woorde om te bereken.' className='inputTextForm' type="text" disabled={isVeldeKort}  onClick={handleVeldeKort} value={inputLeesStuk} />):(<textarea placeholder='Laai 100 of meer woorde om te bereken.' className='inputTextForm' type="text"  value={inputLeesStuk} onChange={handleLeesStuk} />)}</>
    ):(<div className='displayInputTextForm'>{inputLeesStuk}</div>)}
	
  </label>
    </form>
    </div>
{leesStukCount > 99 ? (
  (returnData !== '' ? (

  <div className='resetBtn' onClick={resetUpload}>Bereken nuwe</div>
  )
  :
  (<div className='submitBtn' onClick={handleUpload}>Bereken</div>))):
    ( <div className='submitBtnGreyed' onClick={handleMinWoorde}>Bereken</div>)}

        <br></br><br></br>
  {returnData !== '' ? (
    <div className='dataBoxContainer'>
   <div className='dataBoxMainHeadding'>Die leesbaarheidsgraad van jou leesstuk is soos volg:<hr></hr>
   
   { taalCheck !== 'AFRIKAANS' ? (<div className='nieAFR'>Jou leesstuk is moontlik nie Afrikaans nie, gevolglik kan die resultate onakkuraat wees.  </div>):(<></>)}


   </div>

  
    <div className='dataBoxDivider'>
      
  <div className='dataBox'>
    <div className='dataBoxHeader'>Misindeks <InfoIcon className='pressableIcon' onClick={openMisModal}/></div>
    {scoreMis !== 'F' ? (<>
  <div className='dataDisplayNum'>{scoreMis.split('.')[0]}</div>
  <div className='dataFeedBack'>{misFeedBack}</div>
   </> ):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
  <hr></hr>
  <div className='meerBtnPos'>
  
  </div>
  </div>

  <div className='dataBox'>
    <div className='dataBoxHeader'>McDermid Heyns <InfoIcon className='pressableIcon' onClick={openMcDModal}/></div>
    {scoreMcdermid !== 'F' ? (<>
  <div className='dataDisplayNum'>{scoreMcdermid.split('.')[0]}</div>
  <div className='dataFeedBack'>{mcDermidFeedBack}</div>
   </> ):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
  <hr></hr>
 
  </div>

  <div className='dataBox'>
    <div className='dataBoxHeader'>Flesch Kincaid Grade Level <InfoIcon className='pressableIcon' onClick={openFleschKinModal}/></div>
    {scoreFleschKin !== 'F' ? (<>
  <div className='dataDisplayNum'>{scoreFleschKin.split('.')[0]}</div>
  <div className='dataFeedBack'> {scoreFleschKin.split('.')[0]} jaar se skoolopleiding</div>
   </> ):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
  <hr></hr>
  
  </div>
  
  <div className='dataBox'>
  <div className='dataBoxHeader'>Flesch Reading Ease <InfoIcon className='pressableIcon' onClick={openFleschModal}/></div>
  {scoreFlesch !== 'F' ? (<>
  <div className='dataDisplayNum'>{scoreFlesch.split('.')[0]}</div>
  <div className='dataFeedBack'>{scoreFleschFeedBack}</div>
 </> ):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
  <hr></hr>
 
  </div>
  <div className='dataBox'>
  <div className='dataBoxHeader'>Graadvlak<InfoIcon className='invisIcon' onClick={openMoeiliksteWoordModal}/></div>
  {scoreMis !== 'F' ? (<>
  {!isMisNaSkool ? ( <div className='dataDisplayNum'>Gr. {scoreMis.split('.')[0]}</div>):(<div><div className='dataDisplayNumSmall'>Gr.12 + </div><strong>{misGraadFeedBack}</strong></div>)}
 
  <div className='dataFeedBack'> Gebaseer op Misindeks</div>
 </>):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
  <hr></hr>
  
  
  </div>
</div>
<div className='dataBoxMainHeadding'>Analitiese inligting:<hr></hr></div>
<div className='dataBoxDivider'>

<div className="ekstraInfoBox"> 

<div>
{scoreSinne !== 'F' ? (<>
<div className='dataDisplayNum'>{scoreSinne.split('.')[0]}</div>
<div className='dataBoxHeader'>sinne</div>
</>):(<div className='dataBoxHeader'>Aantal sinne en woorde:</div>)}
{scoreSinne !== 'F' ? (<>
<hr></hr>
  
  <div className='dataDisplayNum'>{scoreAantal.split('.')[0]}</div>
  <div className='dataBoxHeader'>woorde</div>
  </>):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
  
  <div className='dataDisplayNum'></div>

  </div>
  </div>

  <div className="ekstraInfoBox"> 
  <div>
  <div className='dataBoxHeader'>Moeilikste woord: </div> <br></br>
  {scoreMoeilkheid !== 'F' ? (<>
  <div>

  {scoreMoeilkheid.split('.')[0]}

</div>

  <hr></hr>
  <div className='dataBoxHeader'>Leesbaarheidsgraad <InfoIcon className='pressableIcon' onClick={openMoeiliksteWoordModal}/></div>
  <div className='dataDisplayNum'>{scoreWoord.split('.')[0]}</div>
  <div className='dataFeedBack'>{scoreFleschFeedBack}</div>
  
</>):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
<hr></hr>
  </div>
  
 </div>

 

 <div className="ekstraInfoBox"> 
  <div>
  <div className='dataBoxHeader'>Vasvrawoorde:</div>
 <br></br>
 {toetsWoorde !== 'F' ? (
      <>
        {toetsWoorde.split(', ').map((word, index) => (
        <li key={index}>
        {word}
      </li>
      
        ))}
     </> ):(<div className='submitBtn' onClick={handleButtonClickKoop}>Koop toegang</div>)}
      
  <hr></hr><br></br>
  </div>
 </div>

  </div>
  </div>
  
  ): <></>}
</>)}

</div>


   
  )
}

export default MainApp
