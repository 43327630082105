import React, { useState, useEffect } from 'react';
import './modal.css';
import AppLogo from '../assets/heroLogo.svg'



const Vrywaring =()=> {

 
 
 return(
<div>
    
   
<div className='infoSection'>
<div className='infoBlock'>
<h3>Vrywaring:</h3>
<p>Ons leesbaarheidsindeks is in ’n vroeë stadium van ontwikkeling en sal voortdurend verbeter word om te verseker dat dit akkuraat en nuttig is vir ons gebruikers. Die data wat deur hierdie sagteware opgeneem word (uitsluitend persoonlike data) sal vir taalnavorsing gebruik word en om ons stelsel verder te verfyn. Ons waardeer terugvoer om ons in staat te stel om die indeks te verfyn en te optimaliseer vir ’n beter gebruikerservaring.
</p>
</div>
</div>
</div>

 )
}

export default Vrywaring