import React, { useState, useEffect, useRef } from 'react';
//import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import './styles/StyleSheet.css';
import AppLogo from './assets/heroLogo.svg';
import AppHero from './assets/AppHero.svg'
import MainApp from './components/MainApp';
import Vrywaring from './components/Vrywaring';
import Dokumentasie from './components/Dokumentasie';
//import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import GradingIcon from '@mui/icons-material/Grading';
//import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import InfoIcon from '@mui/icons-material/Info';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ModalAuth from './components/ModalAuth';
//import VivALogo from './assets/VivALogo.svg';

function App() {

const [authState, setAuthState] = useState(null)
// nuwe 
const [showModal, setShowModal] = useState(false);
const retryCountRef = useRef(0);

useEffect(() => {
  const checkAuthStatus = () => {
    const userKey = sessionStorage.getItem('authStatus');
    if (userKey && userKey.trim() !== '') {
      setAuthState(userKey);
     // console.log('User key found and state updated', authState);
    } else {
      if (retryCountRef.current < 10) {
       // console.log('No user key found, retrying...', authState);
        retryCountRef.current += 1;
        setTimeout(checkAuthStatus, 1000); // Retry after 1 second
      } else {
        console.log('fout', userKey);
        setShowModal(true);
      }
    }
  };

  checkAuthStatus();
}, []);

const [data, setData] = useState(new FormData());

const handleTryLater = () => {
 // console.log('clicked');
 //api post
 //const userKey = sessionStorage.getItem('key');
 //data.append('userKey', userKey);
 data.append('windowType', 'error leesbaarheidsindeks');
//443
 axios.post('https://leesbaarheidsindeks.viva-afrikaans.org:443/check', data).then((res) => {
console.log('keer terug')
}).then((res) => {

  sessionStorage.clear()
  localStorage.clear()
  window.parent.location.href = 'https://viva-afrikaans.org'; // Replace with the desired URL

 })
.catch((err) => {
   console.error(err);
}
);
 //api post
 
};

  //device info
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
 
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        setIsCollapsed(isMobileDevice());
    }, []); // Empty dependency array ensures this effect runs only once after initial render

  
  // device info

  //nav section
const [isMainApp, setIsMainApp] = useState(true)
const [isDokument, setIsDokument] = useState(false)
//const [isCollapsed, setIsCollapsed] =useState(false)
const [isVrywaar, setIsVrywaar] =useState(false)

const CollapseNav =()=>{
  if (isCollapsed === true) {setIsCollapsed(false)}
  else if (isCollapsed === false) {setIsCollapsed(true)}
}

const NavMainApp =()=>{
  setIsMainApp(true);
  setIsDokument(false);
  setIsVrywaar(false);
  
}

const NavDokument =()=>{
  setIsDokument(true);
  setIsMainApp(false);
  setIsVrywaar(false);
  
}

const NavVrywaar =()=>{
  setIsDokument(false);
  setIsMainApp(false);
  setIsVrywaar(true);
  
}

  return (
   
    <div className='mainContainerDiv'>
     
     <div className='sideBarContainer'>
      <div className='sideBarStyle'>
<Sidebar
//width={'120px'}
//breakPoint={'md'}
collapsed={isCollapsed}
collapsedWidth={'62px'}
transitionDuration={800}

>
      <div className='sideBarTitle'>
        <div className='sideBarTitleBlock'>
          {isCollapsed ? <><MenuOpenIcon className='rotatedIcon' onClick={CollapseNav} /></> : <div className='sideBarCollapse'>Leesbaarheidsindeks <MenuOpenIcon className='pressableIcon' onClick={CollapseNav} /></div>}
          </div>
        </div>
      <div className='sideBarHero'>
        <div>
<img src={AppLogo} alt="leesbaarheidsindeks logo" width={isCollapsed ? '30px' : '140px'}  />
</div>
      </div>
  <Menu
  menuItemStyles={{
    button: ({ level, active, disabled }) => {
      // only apply styles on first level elements of the tree
      if (level === 0)
        return {
          color: disabled ? '#ccc' : '#666',
          backgroundColor: active ? '#f2f2f2' : undefined,
        };
    },
  }}>
      <MenuItem style={{ backgroundColor: isMainApp ? '#27a9e1': '#f2f2f2'}} onClick={NavMainApp}>  {isCollapsed ? <GradingIcon className={ isMainApp ? 'activeIcon' : ''}/> : <div className={isMainApp ? 'sideBarMenuItemActive':'sideBarMenuItem'}>Leesbaarheidsindeks <GradingIcon className={ isMainApp ? 'activeIconInBar' : ''}/></div> } </MenuItem>
      <MenuItem  style={{ backgroundColor: isDokument ? '#27a9e1': '#f2f2f2'}} onClick={NavDokument}> {isCollapsed ? <InfoIcon className={ isDokument ? 'activeIcon' : ''}/> : <div className={isDokument ? 'sideBarMenuItemActive':'sideBarMenuItem'}>Meer inligting <InfoIcon className={ isDokument ? 'activeIconInBar' : ''}/></div>}</MenuItem>
      <MenuItem  style={{ backgroundColor: isVrywaar ? '#27a9e1': '#f2f2f2'}} onClick={NavVrywaar}> {isCollapsed ? <LiveHelpIcon className={ isVrywaar ? 'activeIcon' : ''}/> : <div className={isVrywaar ? 'sideBarMenuItemActive':'sideBarMenuItem'}>Vrywaring <LiveHelpIcon className={ isVrywaar ? 'activeIconInBar' : ''}/></div>}</MenuItem>
     {/* 
     <MenuItem onClick={NavDokument}><div className={isDokument ? 'sideBarMenuItemActive':'sideBarMenuItem'}> Dokumentasie <AutoStoriesIcon/> </div> </MenuItem>
      <SubMenu label="Hulpbronne">
      <MenuItem> Gr1 Tekste </MenuItem>
      <MenuItem> Meer tekste </MenuItem>
        </SubMenu>
      <MenuItem > Help </MenuItem>
      */}
  </Menu>
</Sidebar>
</div>

</div>
<div className='appContainer'>
<div className='appHero'>
<img src={AppHero} alt="leesbaarheidsindeks logo" width='150px'/>
<div className='appHeroTextLine'>
<strong>Bepaal die leesbaarheidsgraad van enige Afrikaanse teks.</strong>
</div>
  </div>
<div className='appContent'>
  {/*
  {isMainApp ?  (<MainApp/>):(<></>)}
  {isDokument ?  (<Dokumentasie/>):(<></>)}


  */}
{/* Render both components */}
<div style={{ display: isMainApp ? 'block' : 'none', zIndex: 1 }}>

{authState === 'U' ? (
        <MainApp authState ={authState} />
      ) : authState === 'A' ? (
        <MainApp authState ={authState}/>
      ) : authState === 'F' ? (
        <MainApp authState ={authState}/>
      ) : authState === 'E' ? (
        <ModalAuth authState ={authState}/>
      ) : (<> 
        {showModal ? (
          <div className="modal">
            <div className="modal-content">
              <h2>Iets het fout gegaan</h2>
              <p>Kontak ons ontwikkelaar <a href="mailto:gustaf@viva-afrikaans.org">gustaf@viva-afrikaans.org</a> of probeer later weer.</p>

              <button onClick={handleTryLater}>Keer terug</button>
            </div>
          </div>
        ) : (
          <div className='loader-overlay'>
            <l-dot-wave
              size="47"
              speed="1"
              color="#27a9e1"
            ></l-dot-wave>
           
          </div>
          
        )}</>
      )}

</div>
<div style={{ display: isDokument ? 'block' : 'none',  zIndex: 2 }}>
  <Dokumentasie/>
  </div>
  <div style={{ display: isVrywaar ? 'block' : 'none',  zIndex: 3 }}>
  <Vrywaring/>
  </div>
   <div className='appFooter'></div>
  </div>
 
  </div>
  
    </div>
  );
}

export default App;
