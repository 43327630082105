import React, { useState, useEffect } from 'react';
import '../../styles/StyleSheet.css'




const MoeiliksteWoordModal =()=> {

 return(
<div>
    

<h2>Moeilikste woord</h2>
<p>Die eenvoudige formule om te bepaal hoe moeilik of hoe maklik ’n woord gelees kan word, is soos volg: 
   <br></br>
    <p>Die lengte van die woord (die aantal karakters/letters waaruit dit bestaan) word by 0,5 getel, en die antwoord van hierdie optelsom word dan met die aantal lettergrepe vermenigvuldig. </p>
    <p>
    Die berekening is dus (woordlengte + 0,5) x aantal lettergrepe.</p>
    
   <p> Deur 0,5 by die woordlengte te tel, voordat dit met die aantal lettergrepe vermenigvuldig word, word ’n basislyntelling aan die woord toegeken. Hierdie aanpassing verseker dat enige woord, selfs eenlettergrepige woorde, met woorde van ander lengtes vergelyk kan word. </p>
    
   <p>Let op dat hierdie redelik eenvoudige formule slegs van objektiewe veranderlikes (bv. karakters, lettergrepe) gebruik maak om die moeilikheidsgraad van ’n woord te bepaal, hoewel konteks en die subjektiwiteit van die taalgebruiker natuurlik ook bydra tot hoe eenvoudig of kompleks ’n woord geag word.</p> 

</p>
</div>

 )
}

export default MoeiliksteWoordModal