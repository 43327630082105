import React, { useState, useEffect } from 'react';
import '../../styles/StyleSheet.css'




const FleschReadingEaseModal =()=> {

 return(
<div>
    

<h2>Flesch Reading Ease</h2>
<p>Hierdie "Engelse" formule is deur Rudolph Flesch in die 1940’s ontwikkel, terwyl hy ’n konsultant by Associated Press was met die verantwoordelikheid om die leesbaarheid van koerante te bepaal (Readable, s.j.). 

Hierdie leesbaarheidsindeks werk volgens ’n skaal van 1-100, waar 30 “baie moeilik” en 70 “maklik” beteken, en 100 dui op leerders wat graad 4 voltooi het.

Hierdie formule is soos volg:
206.835-1.015((aantal woorde)/(aantal sinne))-84.6((aantal lettergrepe)/(aantal woorde)).
</p>
<br></br>
<h3>Leesbaarheidsgraadvlakke:</h3>
<li>0-30 Baie moeilik (Tipies wetenskaplike skryfwerk bv. verhandelinge)</li>
<li>30-50 Moeilik</li>
<li>50-60 Redelik moeilik</li>
<li>60-70 Standaard</li>
<li>70-80 Redelik maklik</li>
<li>80-90 Maklik</li>
<li>90-100 Baie maklik</li>
<br></br>
</div>

 )
}

export default FleschReadingEaseModal