// Modal.js
import React from 'react';
import './modal.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Modal = ({ isOpen, onClose, closeModal, children }) => {
  if (!isOpen) return null;

  return (
   
    <div onClick={onClose} className="modal-overlay">
      

      <div className="modal-container">
      
  <div  className='close-button' onClick={closeModal}>
  <HighlightOffIcon/>
 </div>
        {children}
        <hr></hr>
        <p>Sien "Meer inligting" om verder te lees.</p>
        <div></div>
      </div>
    </div>
  );
};

export default Modal;