import React, { useState, useEffect } from 'react';
import './modal.css';
import AppLogo from '../assets/heroLogo.svg'



const Dokumentasie =()=> {

 
 
 return(
<div>
    <div>
    <img src={AppLogo} alt="Leesvlak logo" width='300px'  />
    </div>
   
<div className='infoSection'>
<div className='infoBlock'>
<h3>Wat is leesbaarheid?</h3>
<p>Dit verwys na hoe maklik (of moeilik) ’n stuk teks is om te verstaan (Lorge, 1949:8; Richards et al., 2002; Begeny & Greene, 2014:1) op grond van faktore, soos die gemiddelde lengte van sinne, die aantal nuwe woorde en hoe grammatikaal kompleks die taal van die teks is (Richards & Richards, 2002; Begeny & Greene, 2014:1). Wanneer die leesbaarheid van ’n teks te moeilik is, kan dit daartoe lei dat die leser die teks bloot ophou lees (DuBay, 2004:1).
</p>
</div>

<div className='infoBlock'>
<h3>Wat is 'n leesbaarheidsindeks?</h3>
<p>’n Leesbaarheidsindeks is ’n statistiese hulpmiddel wat gebruik word om op ’n objektiewe wyse die leesbaarheid van ’n teks te bepaal (Bailin & Grafstein, 2016: hoofstuk 1, par. 1). ’n Leesbaarheidsindeks kwantifiseer met ander woorde die leesbaarheid van ’n teks deur van ’n wiskundige formule gebruik te maak. 
</p>
<p>Taalkundige elemente (bv. woordlengte, sinslengte en die aantal lettergrepe) word dan as veranderlikes in die formule gebruik. Die leesbaarheidstelling wat verkry word, dui op hoe goed iemand wat op ’n sekere onderwysvlak funksioneer, die teks sal verstaan (Van Rooyen 1985:59; Cornelius 2020).
</p>
<p>Leesbaarheidsindekse kan handmatig aangewend word of deur ’n rekenaar bereken word, indien dit gedigitaliseer word. "Statistical tools intended to objectively measure the relative difficulty of texts" (Potter et al., 2013).
</p>
</div>

<div className='infoBlock'>
<h3>Watter leesbaarheidsindekse word deur VivA gebruik?</h3>
<p>Gebruikers kry ’n leesbaarheidstelling gebaseer op twee Afrikaanse leesbaarheidsindekse, naamlik die Misindeks en die McDermid Heyns, en twee Engelse leesbaarheidsindekse, naamlik die Flesch Reading Ease en die Flesch Kincaid Grade Level.</p>
</div>

<div className='infoBlock'>
<h4>•	Misindeks</h4>
<p>Die Misindeks dui in die eerste plek vir enige stuk prosa (en ook inligtingtekste) op die aantal jaar skoolopleiding wat vereis word sodat ’n persoon die teks kan verstaan. Dieselfde telling dui egter ook op hoe moeilik of maklik ’n teks is. Byvoorbeeld, ’n telling van 7 beteken dat die persoon 7 jaar skoolopleiding benodig om die teks te verstaan of dat die persoon graad 7 (standerd 5) moes slaag, of dit dui daarop dat die leesbaarheid van die teks standaard is.</p>
</div>

<div className='infoBlock'>
<h4>•	McDermid Heyns</h4>
<p>Hierdie Afrikaanse formule is op die Engelse Flesch-formule gebaseer, en dit is aanvanklik in Skryfgoed, sagteware om geskrewe tekste te proeflees, ingesluit (Richards et al., 2017:5-6).

Hierdie formule is soos volg: 

RE = 138.8989-(1.0052 x ASL)-(35.4562 x AWL).

RE = voorspelde leesbaarheidstelling; ASL = aantal woorde per sinne (gemiddelde sinslengte); AWL = getal lettergrepe per woord.
</p>
</div>

<div className='infoBlock'>
<h4>•	Flesch Reading Ease</h4>
<p>Hierdie formule is deur Rudolph Flesch in die 1940’s ontwikkel, terwyl hy ’n konsultant by Associated Press was met die verantwoordelikheid om die leesbaarheid van koerante te bepaal (Readable, s.j.). 

Hierdie leesbaarheidsindeks werk volgens ’n skaal van 1-100, waar 30 “baie moeilik” en 70 “maklik” beteken, en 100 dui op leerders wat graad 4 voltooi het.

Hierdie formule is soos volg:
206.835-1.015((aantal woorde)/(aantal sinne))-84.6((aantal lettergrepe)/(aantal woorde)).
</p>
</div>

<div className='infoBlock'>
<h4>•	Flesch Kincaid Grade Level</h4>
<p>In 1976 het die VSA Vloot, tydens die onderneming van ’n studie, die Flesch Reading Ease aangepas om spesifiek die graadvlak van ’n teks te bepaal. Hierdie formule het verskillende name: die Flesch Kincaid-formule, die Flesch Kincaid-skaalformule of die Kincaid-formule (DuBay, 2004:21).

Hierdie formule is soos volg:
0.39((aantal woorde)/(aantal sinne))+11.86((aantal lettergrepe)/(aantal woorde))-15.59.
</p>
</div>

<div className='infoBlock'>
<h3>Wat om in gedagte te hou wanneer ’n telling verkry word.</h3>
<p>Die telling wat verkry word (bv. 4) dui slegs op die strukturele leesbaarheidsgraad van die teks. Dit is nié ’n aanduiding van hoe moeilik die betekenis van die teks is nie (Lorge, 1949:10; Masoni & Guelfi, 2017:531). </p>

<p>’n Teks kan struktureel maklik wees en ’n lae telling hê, maar lesers kan probleme ondervind om die betekenis daarvan te begryp as gevolg van die inhoudelike abstraksievlak daarvan (Vos et al., 2014:56).</p>

<p>Die belangstelling in die inhoud van ’n betrokke teks, die vermoë om teen optimale spoed te lees (Janan & Wray, 2014:129), die vaardighede en intelligensie van die leser, asook sy doel om te lees (Dale & Chall, 1949:5)  is alles faktore  wat ook met leesbaarheid saamhang. Sulke faktore is egter nie struktureel van aard nie en word nie deur die leesbaarheidsindekse op VivA se webtuiste verreken nie. </p>
</div>

<div className='infoBlock'>
<h3>Bronne</h3>

<p>Bailin, A. & Grafstein, A. 2016. Readability: text and context. Hampshire: Palgrave Macmillan.</p>

<p>Begeny, J.C. & Greene, D.J. 2014. Can readability formulas be used to successfully gauge difficulty of reading material. Psychology in the Schools, 51(2):198-215.</p>

<p>Combrink. J. 1992. Hoe om ’n verslag te skryf. Kaapstad: Tafelberg-Uitgewers.</p>

<p>Cornelius, E. 2020. Gewone taal: ’n oorsig. African Sun Media.</p>

<p>Dale, E. & Chall, J.S. 1949. The concept of readability. Elementary English, 26(1):19-26.</p>

<p>DuBay, W.H. 2004. The principles of readability. Online Submission.</p>

<p>Janan, D. & Wray, D. 2014. Reassessing the accuracy and use of readability formulae. Malaysian journal of learning and instruction, 11:127-145.</p>

<p>Jansen, C., Richards, R. & Van Zyl, L. 2017. Evaluating four readability formulas for Afrikaans. Stellenbosch Papers in Linguistics Plus, 53:149-166.</p>

<p>Lorge, I. 1949. Readability formulae - an evaluation. Elementary English, 26(2):86-95.</p>

<p>Richards, J.C. & Richards, S. 2002. Longman dictionary of applied linguistics and language teaching. Harlow, UK: Longman.</p>

<p>Masoni, M. & Guelfi, M.R. 2017. Going beyond the concept of readability to improve
comprehension of patient education materials.</p>

<p>Potter, M.W., Fletcher‐Janzen, E., Reynolds, C.R. & Vannest, K.J. 2013. Readability formulas. In: Encyclopedia of Special Education. https://onlinelibrary-wiley-com.ez.sun.ac.za/doi/pdf/10.1002/9781118660584.ese2011 Date of access: 6 September 2022. </p>

<p>Readable. Flesch Reading Ease and the Flesch Kincaid Grade Level. https://readable.com/readability/flesch-reading-ease-flesch-kincaid-grade-level/. Datum van gebruik: 8 Maart 2024.</p>

<p>Van Rooyen, R. 1986. Eerste Afrikaanse leesbaarheidsformules. Communicatio, 12(1):59-69.</p>

<p>Vos, E., Nel, C. & Van den Berg, R. 2014. Assesseer leesbegripstoetse werklik leesbegrip? Tydskrif vir Taalonderrig, 48(2):53-79.</p>

</div>
</div>
</div>

 )
}

export default Dokumentasie