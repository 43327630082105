import React, { useState, useEffect } from 'react';
import '../../styles/StyleSheet.css'




const FleschKincaidModal =()=> {

 return(
<div>
    

<h2>Flesch Kincaid Grade Level</h2>
<p>In 1976 het die VSA Vloot, tydens die onderneming van ’n studie, die Flesch Reading Ease aangepas om spesifiek die graadvlak van ’n teks te bepaal.  "Engelse" formule het verskillende name: die Flesch Kincaid-formule, die Flesch Kincaid-skaalformule of die Kincaid-formule (DuBay, 2004:21).

Hierdie formule is soos volg:
0.39((aantal woorde)/(aantal sinne))+11.86((aantal lettergrepe)/(aantal woorde))-15.59
 

</p>
</div>

 )
}

export default FleschKincaidModal